import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnInit,
	ViewChild
} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import {IonSlides, ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {AppComponent} from 'src/app/app.component';
import {AllowGpsModalComponent} from '../../../app/components/allow-gps-modal/allow-gps-modal.component';
import {CheckDeliveryRadiusModalComponent} from '../../../app/components/check-delivery-radius-modal/check-delivery-radius-modal.component';
import {DeliveryNotAvailableModalComponent} from '../../../app/components/delivery-not-available-modal/delivery-not-available-modal.component';
import {DeliveryNotAvailableAction} from '../../../app/enums/DeliveryNotAvailableAction';
import {MenuPage} from '../../../app/pages/menu/menu.page';
import {MapsUtils} from '../../../maps/utils/maps';
import {Api} from '../../../smoothr-web-app-core/api/api';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import Address from '../../../smoothr-web-app-core/models/Address';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {TimeUtils} from '../../../smoothr-web-app-core/utils/time-utils';
import {
	coverFlow,
	venueAcceptsOrders
} from '../../../smoothr-web-app-core/utils/utils';

import Map = google.maps.Map;
import MapOptions = google.maps.MapOptions;
import {MapInfoModalComponent} from 'src/app/components/map-info-modal/map-info-modal.component';
import {OrderType} from 'src/smoothr-web-app-core/enums/OrderType';

@Component({
	selector: 'app-map-venue',
	templateUrl: './map-venue.component.html',
	styleUrls: ['./map-venue.component.scss']
})
export class MapVenueComponent extends RepositoryDirective implements OnInit {
	@Input() showSearchInput = false;
	@Input() showVenueList = true;
	@Input() venueToFirstPositionShortId = '';
	@Input() set showMapFirst(value: boolean) {
		if (this.showMap != value) {
			this.showMap = value;
		}
	}
	@ViewChild('mapContainer', {static: true})
	mapElement: ElementRef;
	// @ViewChild(IonInput, { static: true })
	// inputField: IonInput;
	@ViewChild(IonSlides, {static: true})
	slides: IonSlides;
	map: Map;
	mapOptions: MapOptions = {
		maxZoom: 15,
		minZoom: 5,
		disableDefaultUI: true,
		clickableIcons: false,
		styles: [
			{
				featureType: 'poi.business',
				stylers: [{visibility: 'off'}]
			}
		]
	};
	clusterer: MarkerClusterer;
	loading = false;
	loadingAddress = false;
	predictions: Address[] = [];
	private _showPredictions = false;
	showHint = false;
	searchTerm: string;
	allVenues: Venue[] = [];
	localVenues: Venue[] = [];
	selectedVenueForDelivery: Venue;
	showMap = true;
	slidesOpts = {
		slidesPerView: 1,
		coverflowEffect: {
			rotate: 0,
			stretch: 15,
			depth: 10,
			modifier: 2
		},
		on: coverFlow
	};

	showInputForAddress = false;
	showListOfVenues = true;
	selectedVenue: Venue;
	MapsUtils = MapsUtils;
	@Input() selectFirstVenue: boolean = false;
	constructor(
		private snackbarCtrl: MatSnackBar,
		public repository: RepositoryService,
		private translate: TranslateService,
		private router: Router,
		private cdr: ChangeDetectorRef,
		private modalCtrl: ModalController
	) {
		super(repository);
	}

	get relevantVenues(): Venue[] {
		return this.address ? this.localVenues : this.allVenues;
	}

	ngOnInit() {
		super.ngOnInit();
		this.map = new Map(this.mapElement.nativeElement, this.mapOptions);
		// tslint:disable-next-line:no-unused-expression
	}

	async resetMap() {
		if (this.allVenues.length === 0) {
			try {
				this.allVenues = (await Api.getAllVenues()).data
					.map(ven => {
						try {
							ven.openingHours = TimeUtils.sanitizeHours(
								ven.openingHours,
								ven?.specialOpeningHours ?? []
							);
							ven.deliveryHours = TimeUtils.sanitizeHours(ven.deliveryHours);
						} catch (e) {
							console.error({
								message: 'Error while sanitizing hours ' + e,
								venue: ven.name + ' ' + ven.readableId,
								openingHours: ven.openingHours,
								deliveryHours: ven.deliveryHours
							});
						}
						return ven;
					})
					.filter(
						ven =>
							venueAcceptsOrders(ven, PreorderType.DELIVERY) ||
							venueAcceptsOrders(ven, PreorderType.TAKE_AWAY)
					);
				if (this.venueToFirstPositionShortId) {
					const findedObje = this.allVenues.find(
						item => item.readableId == this.venueToFirstPositionShortId
					);
					this.allVenues = this.allVenues.filter(
						item => item.readableId !== this.venueToFirstPositionShortId
					);
					this.allVenues.unshift(findedObje);
				}

				if (this.allVenues.length > 0 && this.selectFirstVenue) {
					this.selectedVenue = this.allVenues[0];
				} else {
					this.selectedVenue = null;
				}
			} catch (e) {}
		}
		await this.setupMap(this.selectedVenue);
	}

	async setupMap(selectedVenue: Venue) {
		if (this.loading) {
			return;
		}
		this.selectedVenue = selectedVenue;
		this.loading = true;
		this.cdr.detectChanges();
		this.clusterer = MapsUtils.addVenuesToMap(
			this.clusterer,
			selectedVenue,
			this.relevantVenues,
			this.map,
			venue => this.setupMap(venue)
		);
		if (
			selectedVenue &&
			selectedVenue.location &&
			selectedVenue.location.coordinates
		) {
			const selectedIndex = this.relevantVenues.findIndex(
				ven => ven._id === selectedVenue._id
			);
			// wait until slides rendered
			await this.slides.length();
			await this.slides.slideTo(selectedIndex);
		}
		this.loading = false;
		this.cdr.detectChanges();
	}

	async loadVenues(address: Address) {
		if (this.loading) {
			return;
		}
		this.loading = true;
		this.cdr.detectChanges();
		this.localVenues = [];
		try {
			this.localVenues = await this.repository.getVenuesByAddress(address);
			this.searchTerm = MapsUtils.addressToString(address);
			if (this.localVenues.length === 0 && !this.selectedVenueForDelivery) {
				this.snackbarCtrl.open(
					this.translate.instant('map_page.no_venues_in_address', {
						title: this.searchTerm
					}),
					null,
					{
						duration: 2000
					}
				);
				this.loading = false;
				// await this.resetSearch();
				this.cdr.detectChanges();
				return;
			}
			if (this.selectedVenueForDelivery) {
				const deliveryVenues = this.localVenues.filter(ven =>
					venueAcceptsOrders(ven, PreorderType.DELIVERY)
				);
				const selectedDeliveryVenue = deliveryVenues.find(
					ven => ven._id === this.selectedVenueForDelivery._id
				);
				if (selectedDeliveryVenue) {
					await this.selectVenue(selectedDeliveryVenue, PreorderType.DELIVERY);
				} else {
					const choice = await DeliveryNotAvailableModalComponent.show(
						this.modalCtrl
					);
					switch (choice) {
						case DeliveryNotAvailableAction.DECLINE:
							this.localVenues = deliveryVenues;
							if (deliveryVenues.length === 0) {
								this.repository.address.next(null);
							} else {
								this.showHint = true;
							}
							await this.setupMap(null);
							break;
						case DeliveryNotAvailableAction.TAKE_AWAY:
							await this.selectVenue(
								this.selectedVenueForDelivery,
								PreorderType.TAKE_AWAY
							);
							break;
					}
				}
				this.selectedVenueForDelivery = null;
				return;
			}
			this.selectedVenue = null;
			this.loading = false;
			await this.setupMap(this.selectedVenue);
		} catch (e) {
			console.error(e);
		}
		if (this.localVenues.length > 0 && this.selectFirstVenue) {
			this.selectedVenue = this.localVenues[0];
		} else {
			this.selectedVenue = null;
		}
		this.loading = false;
		this.cdr.detectChanges();
	}

	async onSlideChange() {
		let index = await this.slides.getActiveIndex();
		if (index >= this.relevantVenues.length) {
			index = 0;
		}
		await this.setupMap(this.relevantVenues[index]);
	}
	selectVenueToDelivery(venue: Venue) {
		this.selectedVenueForDelivery = venue;
	}
	selectVenueFunc(venue: Venue) {
		this.selectedVenue = venue;
		this.setupMap(venue);
	}

	async selectVenue(
		venue: Venue,
		preorderType: any,
		attempt: number = 0,
		prevError: any = null
	) {
		const modalResult = await MapInfoModalComponent.show(
			this.modalCtrl,
			preorderType,
			venue
		);

		if (modalResult !== 'continue') {
			return;
		}

		if (
			preorderType === PreorderType.DELIVERY &&
			venue.distance > venue.deliveryRadius &&
			venue.deliveryByRadius &&
			!venue.isPostalDelivery
		) {
			this.snackbarCtrl.open(
				this.translate.instant('map_page.venue_does_not_delivery_to_address'),
				null,
				{
					duration: 2000
				}
			);
			return;
		}
		if (attempt > 5) {
			this.loading = false;
			this.snackbarCtrl.open(prevError, null, {
				duration: 2000
			});
			return;
		}
		if (this.loading) {
			return;
		}
		this.cdr.detectChanges();
		this.loading = true;
		if (preorderType === PreorderType.DELIVERY) {
			try {
				await this.repository.getVenue(venue._id);
				await AllowGpsModalComponent.show(this.modalCtrl);
				const result: {venue: Venue; address: Address} =
					await CheckDeliveryRadiusModalComponent.show(
						this.modalCtrl,
						this.venue
					);
				if (result) {
					this.repository.venue.next(
						await this.repository.getVenue(result.venue._id)
					);
					this.repository.address.next(result.address);
					this.repository.createOrder(
						this.venue,
						this.address,
						OrderType.PREORDER,
						PreorderType.DELIVERY
					);
					await MenuPage.navigate(this.router);
					this.loading = false;
				}
				this.loading = false;
				this.cdr.detectChanges();

				return;
			} catch (e) {
				this.loading = false;
				return;
			}
		}
		try {
			await this.repository.getVenue(venue._id);
			this.repository.createOrder(
				venue,
				this.address,
				OrderType.PREORDER,
				preorderType
			);
			console.log(
				'!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!'
			);

			await MenuPage.navigate(this.router);
			this.loading = false;
			this.cdr.detectChanges();
		} catch (e) {
			await this.selectVenue(venue, preorderType, attempt + 1, e);
			this.loading = false;
		}
	}

	async loadPlace(pred: any) {
		if (this.loading) {
			return;
		}
		this.loading = true;
		// await this.fillInPlace(pred);
		this.loading = false;
		this.cdr.detectChanges();
	}

	onAddress() {
		super.onAddress();
		// tslint:disable-next-line:no-unused-expression
		new Promise(async () => {
			if (this.address) {
				await this.loadVenues(this.address);
			} else {
				await this.resetMap();
				// this.resetSearch();
			}
		});
	}

	setShowMap(value: boolean) {
		this.showMap = value;
		this.cdr.detectChanges();
	}
	async changesAddress(address: Address) {
		await this.repository.address.next(address);
		await this.onSlideChange();
	}
}
