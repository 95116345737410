import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Api } from 'src/smoothr-web-app-core/api/api';
import GiftCard from 'src/smoothr-web-app-core/models/GiftCard';
import Order from 'src/smoothr-web-app-core/models/Order';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import { axiosErrorToMessage, sleep } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-add-gift-card-dialog',
	templateUrl: './add-gift-card-dialog.component.html',
	styleUrls: ['./add-gift-card-dialog.component.scss'],
})
export class AddGiftCardDialogComponent implements OnInit {
	public enterForm = new FormControl('');
	public promoError: string = '';
	public loading: boolean = false;
	public venue: Venue;
	public order: Order;
	constructor(
		private modalCtrl: ModalController,
		public translate: TranslateService
	) {}
	static async show(modalCtrl: ModalController, venue: Venue, order: Order): Promise<GiftCard> {
		const modal = await modalCtrl.create({
			component: AddGiftCardDialogComponent,
			cssClass: 'show-personal-promocode auto-height',
			componentProps: {
				venue: venue,
				order: order,
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();

		const result = (await modal.onDidDismiss()).data;
		return result;
	}

	ngOnInit() {
		this.enterForm.valueChanges.pipe().subscribe(v => {
			this.promoError = '';
		});
	}
	dismiss() {
		this.modalCtrl.dismiss();
	}
	async checkGiftCard() {
		if (!this.enterForm.value) {
			this.promoError = this.translate.instant('add_gift_card.error');
			return;
		}
		this.loading = true;
		this.promoError = null;
		try {
			const giftCard = (await Api.loadGiftCard(this.enterForm.value.trim(), this.venue._id)).data;
			if (!giftCard.active) {
				this.promoError = this.translate.instant('add_gift_card.error');
				return;
			}
			try {
				this.loading = false;
				giftCard.hash = this.enterForm.value.trim();
				this.modalCtrl.dismiss(giftCard);
			} catch (e) {
				this.promoError = e;
				this.loading = false;
			}
		} catch (e) {
			this.loading = false;
			if (e.response.status === 404) {
				this.promoError = this.translate.instant('add_gift_card.error');
				return;
			}
			this.promoError = axiosErrorToMessage(this.translate, e);
			return;
		}
	}
}
