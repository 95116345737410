import {environment} from '../../../environments/environment';
import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import Order from 'src/smoothr-web-app-core/models/Order';

@Component({
	selector: 'app-select-unable-table-modal',
	templateUrl: './select-unable-table-modal.component.html',
	styleUrls: ['select-unable-table-modal.component.scss']
})
export class SelectUnableTableModalComponent implements OnInit {
	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController) {
		const modal = await modalCtrl.create({
			component: SelectUnableTableModalComponent,
			cssClass: 'only-next-day-modal auto-height',
			componentProps: {},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
		await modal.onDidDismiss();
	}

	ngOnInit() {}

	async dismiss() {
		await this.modalCtrl.dismiss();
	}
}
