import {environment} from './../../../environments/environment';
import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {IonContent, IonSlides, ModalController} from '@ionic/angular';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import * as moment from 'moment';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import validator from 'validator';
import {TosPage} from '../../pages/tos/tos.page';
import {PrivacyPage} from '../../pages/privacy/privacy.page';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {Moment} from 'moment';
import {ValidationUtils} from '../../../smoothr-web-app-core/utils/validation-utils';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {
	calculateGeoDistance,
	MapsUtils
} from '../../../smoothr-web-app-core/utils/maps-utils';
import {
	sleep,
	numberToCurrency,
	getPrice,
	isVenueOpen,
	getSlotsByOrder
} from '../../../smoothr-web-app-core/utils/utils';
import Order from '../../../smoothr-web-app-core/models/Order';
import {AnalyticsService} from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import Article from '../../../smoothr-web-app-core/models/Article';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import {PhoneNumberUtil} from 'google-libphonenumber';

export const phoneCountries: {code: string; tel: string}[] = [
	{
		code: 'de',
		tel: '+49'
	},
	{
		code: 'au',
		tel: '+43'
	},
	{
		code: 'ua',
		tel: '+380'
	}
];
@Component({
	selector: 'app-checkout-modal',
	templateUrl: './checkout-modal.component.html',
	styleUrls: ['checkout-modal.component.scss']
})
export class CheckoutModalComponent
	extends RepositoryDirective
	implements OnInit
{
	orderUtils = OrderUtils;
	loading = false;
	now: moment.Moment;
	pt = PreorderType;
	nameError: string;
	emailError: string;
	phoneError: string;
	streetNumberError: string;
	orderAtError: string;
	streetError: string;
	postalCodeError: string;
	hadPostalCode = false;
	isOpeningTimeSheet = false;
	ou = OrderUtils;
	telCountryOptions: any = {
		cssClass: 'tel-country-popover'
	};
	tip: number;
	environment = environment;
	numberToCurrency = numberToCurrency;
	@ViewChild(IonContent, {static: false}) content;
	@ViewChild(IonSlides, {static: false}) slides: IonSlides;
	slideOptions = {
		spaceBetween: 0,
		initialSlide: 0,
		speed: 400,
		allowTouchMove: false
	};
	isOverviewPage = false;
	slots: Moment[] = [];
	isVenueOpen = false;

	constructor(
		private modalCtrl: ModalController,
		protected repository: RepositoryService,
		private snackbarCtrl: MatSnackBar,
		private translate: TranslateService,
		private analytics: AnalyticsService,
		private cdr: ChangeDetectorRef
	) {
		super(repository);
		if (
			this.address &&
			this.address.postalCode &&
			this.address.postalCode.length > 0
		) {
			this.hadPostalCode = true;
		}
		if (
			this.order &&
			this.order.preorder &&
			this.order.preorder.postalCode &&
			this.address.postalCode.length > 0
		) {
			this.hadPostalCode = true;
		}
	}

	static async show(modalCtrl: ModalController, order: Order, tip: number) {
		const modal = await modalCtrl.create({
			component: CheckoutModalComponent,
			componentProps: {
				tip
			},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
		return await modal.onDidDismiss();
	}

	ngOnInit() {
		super.ngOnInit();
		this.analytics.beginCheckout(this.order);
		this.analytics.checkoutProgress(this.order, null, 1);
		this.analytics.customerBeginsCheckout();
		this.loadSlots();
	}
	onVenue() {
		this.isVenueOpen = isVenueOpen(this.venue);
	}

	onVenueError(error) {
		super.onVenueError(error);
		this.isVenueOpen = false;
		if (this.order) {
			this.order.asap = false;
		}
	}
	onOrder() {
		super.onOrder();
		const postalCodeInOrder =
			this.order &&
			this.order.preorder &&
			this.order.preorder.postalCode &&
			this.order.preorder.postalCode.length > 0;
		if (this.order && this.order.preorder) {
			if (this.repository._previousPreorder) {
				if (!this.order.preorder.name) {
					this.order.preorder.name = this.repository._previousPreorder.name;
				}
				if (!this.order.preorder.email) {
					this.order.preorder.email = this.repository._previousPreorder.email;
				}
				if (!this.order.preorder.phone) {
					this.order.preorder.phone = this.repository._previousPreorder.phone;
				}
				if (!this.order.preorder.companyName) {
					this.order.preorder.companyName =
						this.repository._previousPreorder.companyName;
				}
				if (!this.order.preorder.floor) {
					this.order.preorder.floor = this.repository._previousPreorder.floor;
				}
				if (!this.order.preorder.subcard) {
					this.order.preorder.subcard =
						this.repository._previousPreorder.subcard;
				}
			}
		}
		this.hadPostalCode = this.hadPostalCode || postalCodeInOrder;
		this.cdr.detectChanges();
	}

	onAddress() {
		super.onAddress();
		this.hadPostalCode =
			this.hadPostalCode ||
			(this.address &&
				this.address.postalCode &&
				this.address.postalCode.length > 0);
		this.reloadAddress();
	}

	onCustomer() {
		super.onCustomer();
		if (this.customer) {
			if (this.customer.postalCode && this.customer.postalCode.length > 0) {
				this.hadPostalCode = true;
			}
			if (!this.order.preorder.email) {
				this.order.preorder.email = this.customer.email;
			}
			if (!this.order.preorder.name) {
				this.order.preorder.name = this.customer.name;
			}
			if (!this.order.preorder.phone) {
				this.order.preorder.phone = this.customer.phone;
			}
			if (!this.order.preorder.companyName) {
				this.order.preorder.companyName = this.customer.companyName;
			}
			if (!this.order.preorder.floor) {
				this.order.preorder.floor = this.customer.floor;
			}
			if (!this.order.preorder.subcard) {
				this.order.preorder.subcard = this.customer.subcard;
			}
			if (this.order.preorder.type === PreorderType.DELIVERY) {
				if (!this.order.preorder.street) {
					this.order.preorder.street = this.customer.street;
				}
				if (!this.order.preorder.postalCode) {
					this.order.preorder.postalCode = this.customer.postalCode;
				}
				if (!this.order.preorder.city) {
					this.order.preorder.city = this.customer.city;
				}
				if (
					!this.order.preorder.number &&
					this.order.preorder.street === this.customer.street
				) {
					this.order.preorder.number = this.customer.number;
				}
			}
		}
	}

	async validate(): Promise<boolean> {
		this.nameError = null;
		this.emailError = null;
		this.phoneError = null;
		this.orderAtError = null;
		this.streetNumberError = null;
		this.postalCodeError = null;
		this.streetError = null;
		if (
			(!this.order.preorder.name || this.order.preorder.name.length < 3) &&
			this.order.preorder.type !== PreorderType.INSIDE
		) {
			this.nameError = this.translate.instant('errors.name');
			this.snackbarCtrl.open(this.nameError, null, {
				duration: 2000
			});
			return false;
		}
		if (
			(!this.order.preorder.email ||
				!validator.isEmail(this.order.preorder.email)) &&
			this.order.preorder.type !== PreorderType.INSIDE
		) {
			this.emailError = this.translate.instant('errors.email');
			this.snackbarCtrl.open(this.emailError, null, {
				duration: 2000
			});
			return false;
		}
		if (this.order.preorder.type !== PreorderType.INSIDE) {
			this.phoneError = this.validatePhone(this.order.preorder.phone);
		}
		console.log(this.phoneError);
		if (this.phoneError) {
			this.snackbarCtrl.open(this.phoneError, null, {
				duration: 2000
			});
			return false;
		}
		// Address was changed in checkout modal check if venue delivers to this new address
		// new address needs to be checked if its in the delivery range
		if (
			OrderUtils.isDelivery(this.order) &&
			this.order.preorder.street !== this.address.street
		) {
			this.order.preorder.street = this.order.preorder.street.trim();
			this.order.preorder.number = this.order.preorder.number.trim();
			if (this.order.preorder.street === '') {
				this.streetError = this.translate.instant('address.street');
				this.snackbarCtrl.open(this.streetError, null, {
					duration: 2000
				});
				return false;
			}
			try {
				const pred =
					this.order.preorder.street +
					' ' +
					this.order.preorder.number +
					', ' +
					this.order.preorder.city +
					' ' +
					this.order.preorder.postalCode;
				const geocode = await MapsUtils.getPlace(pred);
				const address = MapsUtils.placeToAddress(geocode);
				const addressValidationResult = ValidationUtils.validateAddress(
					address,
					true
				);
				if (addressValidationResult) {
					this.streetError = this.translate.instant(addressValidationResult);
					return false;
				}
				const delivers =
					(this.venue.deliveryByRadius &&
						calculateGeoDistance(
							address.lat,
							address.lng,
							this.venue.location.coordinates[1],
							this.venue.location.coordinates[0]
						) <= this.venue.deliveryRadius) ||
					(this.venue.isPostalDelivery &&
						this.venue.deliveryPostalCodes.indexOf(address.postalCode));
				if (!delivers) {
					this.streetError = this.translate.instant('errors.street_delivery');
					this.snackbarCtrl.open(this.streetError, null, {
						duration: 2000
					});
					return false;
				}
				this.repository.address.next(address);
				this.order.preorder.street = this.address.street;
			} catch (e) {
				this.streetError = this.translate.instant('errors.street_not_found');
				this.snackbarCtrl.open(this.streetError, null, {
					duration: 2000
				});
				return false;
			}
		}

		if (
			this.order.preorder.type === PreorderType.DELIVERY &&
			(!this.order.preorder.number || this.order.preorder.number.length < 1)
		) {
			this.streetNumberError = this.translate.instant('errors.street_number');
			this.snackbarCtrl.open(this.streetNumberError, null, {
				duration: 2000
			});
			return false;
		}
		if (
			this.order.preorder.type === PreorderType.DELIVERY &&
			(!this.order.preorder.postalCode ||
				this.order.preorder.postalCode.length < 1)
		) {
			this.postalCodeError = this.translate.instant('errors.postal');
			this.snackbarCtrl.open(this.postalCodeError, null, {
				duration: 2000
			});
			return false;
		}
		if (
			(OrderUtils.isInside(this.order) || OrderUtils.isTakeAway(this.order)) &&
			!this.order.orderAt
		) {
			this.order.orderAt = null;
			this.order.asap = true;
			return true;
		}
		if (!this.order.orderAt) {
			this.orderAtError = this.translate.instant('errors.order_at');
			this.order.orderAt = null;
			this.snackbarCtrl.open(this.orderAtError, null, {
				duration: 2000
			});
			return false;
		}
		if (this.order.orderAt.isBefore(moment())) {
			this.orderAtError = this.translate.instant('errors.order_at');
			this.order.orderAt = null;
			this.snackbarCtrl.open(this.orderAtError, null, {
				duration: 2000
			});
			return false;
		}
		return true;
	}

	async close() {
		const data = {
			preorder: this.order.preorder,
			orderAt: this.order.orderAt,
			tip: this.tip
		};
		if (!(await this.validate())) {
			await sleep(100);
			const firstErrorElement: HTMLElement = [...this.content.el.children].find(
				el => {
					return (
						el.className
							.split(' ')
							.findIndex(clazz => clazz === 'input-error') >= 0
					);
				}
			);
			if (firstErrorElement !== undefined) {
				await this.content.scrollToPoint(0, firstErrorElement.offsetTop, 300);
			}
			return;
		}
		this.repository.previousPreorder.next(this.order.preorder);
		await this.modalCtrl.dismiss(data);
	}

	async backOrDismiss() {
		if (this.isOverviewPage) {
			await this.editPersonalData();
		} else {
			await this.dismiss();
		}
	}

	async dismiss() {
		await this.modalCtrl.dismiss();
	}
	validatePhone(orderPhone: string): string {
		const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
		try {
			if (orderPhone.slice(0, 2) === '00') {
				orderPhone = orderPhone.replace(/^.{2}/g, '+');
				this.order.preorder.phone = orderPhone;
			}
			const phoneNumber = phoneUtil.parse(orderPhone);
			if (
				phoneUtil.isValidNumber(phoneNumber) &&
				phoneUtil.isPossibleNumber(phoneNumber)
			) {
				return '';
			} else {
				return this.translate.instant('errors.phone');
			}
		} catch (e) {
			return this.translate.instant('errors.phoneCountry');
		}
	}
	reloadAddress() {
		if (
			this.order &&
			this.order.preorder &&
			this.address &&
			this.order.preorder.type === PreorderType.DELIVERY
		) {
			this.order.preorder.city = this.address.city;
			this.order.preorder.street = this.address.street;
			if (this.address.postalCode && this.address.postalCode.length > 0) {
				this.order.preorder.postalCode = this.address.postalCode;
			}
			this.order.preorder.country = this.address.country;
			this.order.preorder.number = this.address.number;
		}
	}

	async toOverview() {
		const result = await this.validate();
		if (!result) {
			this.isOverviewPage = false;
			return;
		}
		await this.slides.update();
		await this.slides.slideNext();
		console.log('Slide next');
		this.isOverviewPage = true;
	}

	async editPersonalData() {
		await this.slides.slideTo(0);
		this.isOverviewPage = false;
	}

	async openTos() {
		window.open(TosPage.url, '_blank');
	}

	async openPrivacy() {
		window.open(PrivacyPage.url, '_blank');
	}

	slotToText(slot: Moment): string {
		if (!slot) {
			return this.translate.instant('checkout_modal.choose_slot');
		}
		if (!moment.isMoment(slot)) {
			slot = moment(slot);
		}
		const now = moment();
		const sameDay =
			slot.dayOfYear() === now.dayOfYear() && slot.year() === now.year();
		if (sameDay) {
			return slot.format('HH:mm');
		} else {
			return slot.format('HH:mm / DD.MM.yyyy');
		}
	}

	getPlaceholder(key: string): string {
		return `${this.translate.instant(key)} ${this.translate.instant(
			'checkout_modal.type'
		)}`;
	}

	priceOfOption(
		articleGroup: ArticleGroup,
		articleOption: ArticleOption
	): number {
		const price = getPrice(
			articleOption.article,
			OrderType.PREORDER,
			this.order.preorder.type
		);
		if (
			OrderUtils.isBogoOrFreeArticlePromo(this.order) &&
			articleGroup.isPromo
		) {
			return 0;
		}
		return price * articleOption.quantity;
	}

	hiddenInCart(article: Article): boolean {
		return (
			article.tags &&
			article.tags.find(tag => tag.identifier === 'hide_cart') !== undefined
		);
	}

	async onButtonClick() {
		if (!(await this.validate())) {
			return;
		}
		if (!this.isOverviewPage) {
			await this.toOverview();
		} else {
			await this.close();
		}
	}
	async loadSlots() {
		this.slots = [];
		this.order.orderAt = null;
		if (!this.venue) {
			this.snackbarCtrl.open(this.translate.instant('errors.venue'), null, {
				duration: 2000
			});
			return;
		}
		if (this.loading) {
			return;
		}
		this.loading = true;
		try {
			this.slots = (await getSlotsByOrder(this.venue, this.order)).map(slot =>
				moment(slot.time)
			);
			if (this.slots.length === 0) {
				this.loading = false;
				this.snackbarCtrl.open(
					this.translate.instant('checkout_modal.no_slots'),
					null,
					{
						duration: 2000
					}
				);
				return;
			}
			if (
				this.order.preorder.type === PreorderType.TAKE_AWAY &&
				this.isVenueOpen
			) {
				this.slots = [null, ...this.slots];
			}
		} catch (e) {
			this.snackbarCtrl.open(
				this.translate.instant('checkout_modal.time_error'),
				null,
				{
					duration: 2000
				}
			);
			console.error(e);
		}
		this.loading = false;
	}
	slotSelected($event) {
		this.order.orderAt = $event;
		if (!$event) {
			this.order.asap = true;
		} else {
			this.order.asap = false;
		}
	}
	changed(autoFillNumber: any) {
		const foundCountryCode = phoneCountries.find(
			it => it.code === this.translate.currentLang
		);
		if (
			!autoFillNumber.includes('+', 0) &&
			autoFillNumber.length >= 8 &&
			autoFillNumber.length - 1 !== this.order.preorder.phone.length &&
			foundCountryCode
		) {
			this.order.preorder.phone = foundCountryCode.tel + autoFillNumber;
		} else {
			this.order.preorder.phone = autoFillNumber;
		}
	}
	calcPoints() {
		return this.order.orderedArticles
			.filter(it => it.redeemWithPiggyPoints)
			.reduce((a, b) => a - (b.redeemPointsWithPiggy ?? 0), 0);
	}
}
