import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from 'src/app/services/navigation.service';
import {environment} from 'src/environments/environment';
import Article from 'src/smoothr-web-app-core/models/Article';
import {Coupon} from 'src/smoothr-web-app-core/models/Coupon';
import Customer from 'src/smoothr-web-app-core/models/Customer';
import Order from 'src/smoothr-web-app-core/models/Order';
import {ShowPersonalPromocodeComponent} from '../show-personal-promocode/show-personal-promocode.component';
import {isAvailable} from 'src/smoothr-web-app-core/utils/utils';
import {OrderType} from 'src/smoothr-web-app-core/enums/OrderType';
import {TerminalorderType} from 'src/smoothr-web-app-core/enums/TerminalorderType';

@Component({
	selector: 'app-coupon-item-render',
	templateUrl: './coupon-item-render.component.html',
	styleUrls: ['coupon-item-render.component.scss']
})
export class CouponItemRenderComponent {
	@Input() item: Coupon;
	@Input() article: Article;
	@Output() close = new EventEmitter<void>();
	@Input() order: Order;
	@Input() customerData: Customer;
	@Output() selectCoupon = new EventEmitter<Coupon>();
	@Output() selectArticle = new EventEmitter<Article>();

	constructor(
		private modalCtrl: ModalController,
		private navService: NavigationService,
		private router: Router,
		private translate: TranslateService
	) {}

	closeClick() {
		this.close.emit();
	}

	async inTheSot(item: Coupon) {
		await ShowPersonalPromocodeComponent.show(
			this.modalCtrl,
			this.translate.instant('loyalty_page.personal_qr'),
			this.translate.instant('loyalty_page.personal_qr_subtitle'),
			environment.urlQrCode +
				'user/' +
				this.customerData?._id +
				'/coupon/' +
				item?._id
		);
	}
	async inTheTresen(item: Coupon) {
		await ShowPersonalPromocodeComponent.show(
			this.modalCtrl,
			'Personal QR Code',
			'Bitte zeigen Sie QR Code es dem Kellner',
			environment.urlQrCode +
				'user/' +
				this.customerData?._id +
				'/coupon/' +
				item?._id
		);
	}
	inTheApp(item: Coupon) {
		this.selectCoupon.emit(item);
	}
	inTheAppArticle(article: Article) {
		this.selectArticle.emit(article);
	}
	async inTheSotArticle(article: Article) {
		await ShowPersonalPromocodeComponent.show(
			this.modalCtrl,
			this.translate.instant('loyalty_page.personal_qr'),
			this.translate.instant('loyalty_page.personal_qr_subtitle'),
			environment.urlQrCode +
				'user/' +
				this.customerData?.uid +
				'/article-coupon/' +
				article?._id
		);
	}
	async inKasse(item: Coupon) {
		await ShowPersonalPromocodeComponent.show(
			this.modalCtrl,
			this.translate.instant('loyalty_page.personal_qr'),
			this.translate.instant('loyalty_page.personal_qr_subtitle'),
			environment.urlQrCode + 'user/' + this.customerData?._id
		);
	}
	checkInSotAvaibility(article: Article) {
		if (article) {
			return isAvailable(
				article?.availability,
				OrderType.TERMINAL,
				null,
				TerminalorderType.INSIDE
			);
		}
		return true;
	}
}
