import {environment} from 'src/environments/environment';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ModalController} from '@ionic/angular';
import RepositoryDirective from 'src/smoothr-web-app-core/directives/repository-directive';
import {RepositoryService} from 'src/smoothr-web-app-core/services/repository/repository.service';
import {AppComponent} from '../../app.component';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import {sleep} from '../../../smoothr-web-app-core/utils/utils';
import Table from '../../../smoothr-web-app-core/models/Table';
import {Api} from '../../../smoothr-web-app-core/api/api';
import {TranslateService} from '@ngx-translate/core';
import {AppAddPointsComponent} from '../app-add-points/app-add-points.component';
import Order from 'src/smoothr-web-app-core/models/Order';

@Component({
	selector: 'app-scan-order-points',
	templateUrl: './scan-order-points.component.html',
	styleUrls: ['./scan-order-points.component.scss']
})
export class ScanOrderPointsModal implements OnInit {
	scanning = true;
	loading = false;
	venue: Venue;

	constructor(
		private snackBarCtrl: MatSnackBar,
		private router: Router,
		private modalCtrl: ModalController,
		public translate: TranslateService,
		private repository: RepositoryService
	) {}

	/**
	 * returns table number
	 */
	static async show(modalCtrl: ModalController, venue: Venue): Promise<Order> {
		const resultScan = await modalCtrl.create({
			component: ScanOrderPointsModal,
			cssClass: 'scan-qr-modal',
			componentProps: {
				venue
			}
		});
		await resultScan.present();
		const res = (await resultScan.onDidDismiss()).data;
		await sleep(100);
		return res;
	}
	ngOnInit() {
		console.log(environment.webAppUrl + '/scan-qr/table/1');
		// this.scanSuccessHandler('smoothr://6422d190aaa7a300124d8da3');
	}
	close() {
		this.modalCtrl.dismiss();
	}

	startScanning() {
		this.scanning = true;
	}

	stopScanning() {
		this.scanning = false;
	}

	scanSuccessHandler($event: string) {
		this.loading = true;
		this.snackBarCtrl.open(
			'Wir reservieren den Tisch für dich. Einen Moment bitte...',
			null,
			{
				duration: 2000
			}
		);
		this.stopScanning();
		try {
			console.log($event);
			this.takeOverOrder($event.split('smoothr://')[1]);
		} catch (e) {
			this.loading = false;
			this.snackBarCtrl.dismiss();
			this.snackBarCtrl.open('Bitte QR-Code überprüfen', null, {
				duration: 2000
			});
		}
	}

	async takeOverOrder(orderId: string) {
		console.log(orderId);
		if (orderId == null) {
			this.loading = false;
			this.snackBarCtrl.dismiss();
			this.snackBarCtrl.open('Bitte QR-Code überprüfen', null, {
				duration: 2000
			});
			return;
		}
		try {
			await sleep(100);

			const updatedOrder = (
				await Api.addPointsByOrderId(
					orderId,
					this.repository?._customerAuth?.customer?.uid
				)
			).data;
			await sleep(100);
			await this.modalCtrl.dismiss(updatedOrder);
		} catch (e) {
			console.log(e);
			this.snackBarCtrl.dismiss();
			this.snackBarCtrl.open('Fehler beim Abrufen des Tisches: ' + e, null, {
				duration: 2000
			});
			this.modalCtrl.dismiss();
		}
		this.loading = false;
	}
}
