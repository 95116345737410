import {Component, EventEmitter, Input, Output} from '@angular/core';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import Article from '../../../smoothr-web-app-core/models/Article';
import {
	getPrice,
	numberD,
	numberToCurrency
} from '../../../smoothr-web-app-core/utils/utils';
import {DisplayIdentifier} from '../../../smoothr-web-app-core/enums/DisplayIdentifier';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {environment} from '../../../environments/environment';
import {getAvailability} from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-article-option-group-checkbox',
	templateUrl: './article-option-group-checkbox.component.html',
	styleUrls: ['article-option-group-checkbox.component.scss']
})
export class ArticleOptionGroupCheckboxComponent {
	@Input() optionGroup: OptionGroup;
	@Input() selected: ArticleOption[];
	@Input() article: Article;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Input() currency: string;
	@Input() preorderType: PreorderType;
	@Output() openInfo = new EventEmitter<Article>();

	ot = OrderType;
	di = DisplayIdentifier;
	numberToCurrency = numberToCurrency;
	getPrice = getPrice;

	constructor() {}

	isHidden(article: Article): boolean {
		return (
			this.preorderType &&
			!getAvailability(article, OrderType.PREORDER, this.preorderType)
		);
	}

	onSelectionChange(article: Article, quantity: number) {
		console.log(this.optionGroup);
		console.log('on selection change', article.name.de, quantity);
		if (this.isDisabled(article)) {
			return;
		}
		const articleOption = new ArticleOption();
		articleOption.article = article;
		articleOption.group = this.optionGroup._id;
		articleOption.quantity = quantity;
		this.selection.emit(articleOption);
	}

	toggle(option: Article) {
		this.onSelectionChange(
			option,
			this.selected.map(ao => ao.article?._id).indexOf(option?._id) < 0 ? 1 : -1
		);
	}

	isDisabled(option: Article): boolean {
		return (
			option.requirements &&
			option.requirements.min > 0 &&
			this.isOptionSelected(option)
		);
	}

	isOptionSelected(option: Article): boolean {
		return (
			this.selected.find(
				sel =>
					sel.article._id === option._id && sel.group === this.optionGroup._id
			) !== undefined
		);
	}

	openInfoClick(option: Article) {
		this.openInfo.emit(option);
	}

	checkPfandTag(article: Article) {
		if (article.tags?.length === 0) {
			return '';
		}
		const deposit25 = article.tags.find(it => it.identifier === 'deposit_25')
			? 'deposit25'
			: '';
		const deposit15 = article.tags.find(it => it.identifier === 'deposit_15')
			? 'deposit15'
			: '';
		const deposit8 = article.tags.find(it => it.identifier === 'deposit_8')
			? 'deposit8'
			: '';

		return deposit15 || deposit25 || deposit8;
	}

	isAllergens(article: Article) {
		if (article?.compound?.allergens)
			return Object.values(article?.compound?.allergens)?.find(
				it => it === true
			);
		else return null;
	}

	isAddit(article: Article) {
		if (article?.compound?.additives)
			return Object.values(article?.compound?.additives)?.find(
				it => it === true
			);
		else return null;
	}
	isHaveAnyValueMoreZero(article: Article & {nutritions?: any}) {
		if (!article?.nutritions) {
			return;
		}
		return Object.values(article?.nutritions)
			.map(it => (numberD(it) ? numberD(it) : 0))
			.find(it => it > 0);
	}
}
