import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {sleep} from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-show-personal-promocode',
	templateUrl: './show-personal-promocode.component.html',
	styleUrls: ['show-personal-promocode.component.scss']
})
export class ShowPersonalPromocodeComponent implements OnInit {
	constructor(public modal: ModalController) {}

	title: string = '';
	subtitle: string = '';

	qrCodeString: string = '';
	ngOnInit() {}
	static async show(
		modalCtrl: ModalController,
		title: string = '',
		subtitle: string = '',
		qrCodeString: string = ''
	) {
		const modal = await modalCtrl.create({
			component: ShowPersonalPromocodeComponent,
			cssClass: 'show-personal-promocode auto-height',
			componentProps: {
				title,
				subtitle,
				qrCodeString
			},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
		await modal.onDidDismiss();
		await sleep(200);
	}

	close() {
		this.modal.dismiss();
	}
}
