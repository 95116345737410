import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {HomePage} from '../home/home.page';
import {MenuPage} from '../menu/menu.page';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {environment} from '../../../environments/environment';

@Component({
	selector: 'app-tos',
	templateUrl: './tos.page.html',
	styleUrls: ['tos.page.scss']
})
export class TosPage {
	static url = 'tos';
	environment = environment;

	constructor(
		private repository: RepositoryService,
		private router: Router
	) {}

	static async navigate(router: Router) {
		await router.navigate([TosPage.url], {
			queryParamsHandling: 'merge'
		});
	}

	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
