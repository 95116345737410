import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AppComponent} from 'src/app/app.component';

@Component({
	selector: 'app-map',
	templateUrl: './map.page.html',
	styleUrls: ['map.page.scss']
})
export class MapPage {
	static url = 'map';
	static async navigate(router: Router) {
		return await router.navigate([MapPage.url], {
			queryParamsHandling: 'merge'
		});
	}
	showMapFirst() {
		return AppComponent.largeScreen;
	}
}
