import Assets from './Assets';
import {ByType} from './ByType';
import Localization from './Localization';
import PiggyReward from './PiggyReward';

export interface Coupon {
	_id: any;
	active: boolean;
	availability?: ByType<boolean>;
	amount: number;
	cities?: string[];
	code: string;
	countries?: string[];
	customerGroup?: string;
	from?: string;
	giftCards?: string[];
	usedGiftCards?: string[];
	mov?: string | number;
	isMultipleApplicable: boolean;
	states?: string[];
	to?: string;
	label?: string | Coupon;
	name: string;
	type: CouponType;
	value?: any;
	venues: string[];
	description?: Localization;
	image?: Assets;
	availableForCustomerToUse?: boolean;
	piggyReward: PiggyReward;
	redeemableAtCheckoutOnly?: boolean;
}
export enum CouponType {
	ABSOLUTE = 'absolute',
	RELATIVE = 'relative',
	ABSOLUTE_ARTICLE = 'absolute_article',
	RELATIVE_ARTICLE = 'relative_article',
	GIFT_CARD = 'gift_card',
	DELIVERY_FEE = 'delivery_fee',
	BOGO = 'bogo',
	REWARD = 'reward',
	FREE_ARTICLE = 'free_article',
	VOID = 'void',
	PLUS_ONE = 'plus_one_free'
}
