import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const moschmoschCustomerGroup = 'moschmosch';
export const customerGroup = moschmoschCustomerGroup;

const supportEmail = {
	moschmosch: 'moschmosch@smoothr.de'
};
const firebaseConfig = {
	moschmosch: {
		apiKey: 'AIzaSyB1Y4wG6BoFE2hi8irI30ofKfscXoEPPDw',
		authDomain: 'prod-env-smoothr.firebaseapp.com',
		databaseURL: 'https://prod-env-smoothr.firebaseio.com',
		projectId: 'prod-env-smoothr',
		storageBucket: 'prod-env-smoothr.appspot.com',
		messagingSenderId: '640058019537',
		appId: '1:640058019537:web:ca602e406b9531eacbfff0',
		measurementId: 'G-LWLZXZE0NW'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyDZOQFO_t7v2VO9D2_PrQdyirV5YgOFLPM',
	baseUrl: 'https://api.smoothr.de',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment,
	facebookPixel: '',
	gaId: '',
	urlQrCode: 'smoothr://'
};
