import {Component, EventEmitter, Input, Output} from '@angular/core';
import Article from 'src/smoothr-web-app-core/models/Article';
import Customer from 'src/smoothr-web-app-core/models/Customer';

@Component({
	selector: 'app-loyalty-items-render',
	templateUrl: './loyalty-items-render.component.html',
	styleUrls: ['./loyalty-items-render.component.scss']
})
export class LoyaltyItemsRenderComponent {
	constructor() {}
	loyaltyArticles: (Article & {
		activeLoyalty?: boolean;
		pointsLeft?: number;
	})[] = [];
	@Input() set ashPointItems(value: Article[]) {
		this.loyaltyArticles = value.map(it => {
			const newObj = {
				...it,
				activeLoyalty: this.ifUserCanOrderArticle(it),
				pointsLeft: this.howManyPointsLeft(it)
			};
			return newObj;
		});
	}
	@Output() buttonClick = new EventEmitter<Article>();
	@Input() customer: Customer;

	selectItem(item: Article) {
		this.buttonClick.emit(item);
	}
	ifUserCanOrderArticle(article: Article) {
		return article.isPiggyLoyalty &&
			article.piggyPointsNeeded > 0 &&
			!this.customer?.isAnonymous
			? this.customer?.piggyPoints -
					(this?.customer?.usedPiggyPoints ?? 0) -
					article.piggyPointsNeeded >=
					0
			: false;
	}
	howManyPointsLeft(article) {
		if (
			article.isPiggyLoyalty &&
			article.piggyPointsNeeded > 0 &&
			!this.customer?.isAnonymous
		) {
			return this.customer?.piggyPoints - article.piggyPointsNeeded;
		} else {
			return 0;
		}
	}
}
