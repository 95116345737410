import {Component} from '@angular/core';
import {ModalController} from '@ionic/angular';

interface ModalInfoItem {
	title?: string;
	info?: string;
	mainButton?: string;
	closeButton?: string;
}

@Component({
	selector: 'app-info-modal',
	templateUrl: './info-modal.component.html',
	styleUrls: ['info-modal.component.scss']
})
export class InfoModalComponent {
	constructor(private modalCtrl: ModalController) {}

	title = '';
	info = '';
	mainButton = '';
	closeButton = '';

	static async show(
		modalCtrl: ModalController,
		{title, info, mainButton, closeButton}: ModalInfoItem
	) {
		const modal = await modalCtrl.create({
			cssClass: 'info-modal auto-height',
			component: InfoModalComponent,
			componentProps: {
				title,
				info,
				mainButton,
				closeButton
			},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();

		const result = (await modal.onDidDismiss()).data;
		return result;
	}
	dismiss(value: boolean) {
		this.modalCtrl.dismiss(value);
	}
}
