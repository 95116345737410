import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';
import 'zone.js/dist/zone-error';

export const moschmoschCustomerGroup = 'moschmosch';
export const customerGroup = moschmoschCustomerGroup;

const supportEmail = {
	moschmosch: 'moschmosch@smoothr.de'
};
const firebaseConfig = {
	moschmosch: {
		apiKey: 'AIzaSyBx3rlbR5y81d9sWT4DJEfDlqFyqUmXaz0',
		authDomain: 'dev-env-216009.firebaseapp.com',
		databaseURL: 'https://dev-env-216009.firebaseio.com',
		projectId: 'dev-env-216009',
		storageBucket: 'dev-env-216009.appspot.com',
		messagingSenderId: '1004449889395',
		appId: '1:1004449889395:web:91d2cb05d866f683e0fe06',
		measurementId: 'G-9DMY6EBSLJ'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyB5fk3YGwTbSC5X_MclxSkjxWPJzosikzg',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment,
	facebookPixel: '',
	gaId: '',
	urlQrCode: 'smoothr://'
};
