import {environment} from 'src/environments/environment';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ModalController} from '@ionic/angular';
import RepositoryDirective from 'src/smoothr-web-app-core/directives/repository-directive';
import {RepositoryService} from 'src/smoothr-web-app-core/services/repository/repository.service';
import {AppComponent} from '../../app.component';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import {sleep} from '../../../smoothr-web-app-core/utils/utils';
import Table from '../../../smoothr-web-app-core/models/Table';
import {Api} from '../../../smoothr-web-app-core/api/api';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'app-scan-qr',
	templateUrl: './scan-qr.component.html',
	styleUrls: ['./scan-qr.component.scss']
})
export class ScanQrModal implements OnInit {
	scanning = true;
	loading = false;
	venue: Venue;

	constructor(
		private snackBarCtrl: MatSnackBar,
		private router: Router,
		private modalCtrl: ModalController,
		public translate: TranslateService
	) {}

	/**
	 * returns table number
	 */
	static async show(modalCtrl: ModalController, venue: Venue): Promise<Table> {
		const resultScan = await modalCtrl.create({
			component: ScanQrModal,
			cssClass: AppComponent.largeScreen
				? 'item-modal large-modal'
				: 'item-modal',
			componentProps: {
				venue
			}
		});
		await resultScan.present();
		const res = (await resultScan.onDidDismiss()).data;
		await sleep(100);
		return res;
	}
	ngOnInit() {
		// console.log(environment.webAppUrl + '/scan-qr/table/1')
		// this.scanSuccessHandler(environment.webAppUrl + '/scan-qr/table/1');
	}
	close() {
		this.modalCtrl.dismiss();
	}

	startScanning() {
		this.scanning = true;
	}

	stopScanning() {
		this.scanning = false;
	}

	scanSuccessHandler($event: string) {
		this.loading = true;

		this.stopScanning();
		try {
			console.log($event);
			console.log(environment.webAppUrl + '/scan-qr/table/');

			this.takeOverOrder($event.substring($event.lastIndexOf('/') + 1));
		} catch (e) {
			this.loading = false;
			this.snackBarCtrl.dismiss();
			this.snackBarCtrl.open('Bitte QR-Code überprüfen', null, {
				duration: 2000
			});
		}
	}

	async takeOverOrder(tableNumber: string) {
		console.log(tableNumber);
		if (tableNumber == null) {
			this.loading = false;
			this.snackBarCtrl.dismiss();
			this.snackBarCtrl.open('Bitte QR-Code überprüfen', null, {
				duration: 2000
			});
			return;
		}
		try {
			await sleep(100);

			const table = (await Api.getTable(this.venue?._id, tableNumber)).data;
			console.log(tableNumber);
			await sleep(100);
			await this.modalCtrl.dismiss(table);
		} catch (e) {
			console.log(e);
			this.snackBarCtrl.dismiss();
			this.snackBarCtrl.open('Fehler beim Abrufen des Tisches: ' + e, null, {
				duration: 2000
			});
			this.modalCtrl.dismiss();
		}
		this.loading = false;
	}
}
