import {HomePage} from 'src/app/pages/home/home.page';
import {environment} from 'src/environments/environment';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';
import {RepositoryService} from 'src/smoothr-web-app-core/services/repository/repository.service';
import {Location} from '@angular/common';
import {MenuPage} from 'src/app/pages/menu/menu.page';

@Component({
	selector: 'app-toolbar-logo',
	templateUrl: './toolbar-logo.component.html',
	styleUrls: ['./toolbar-logo.component.scss']
})
export class ToolbarLogoComponent {
	environment = environment;
	@Input() isLoyaltyPages: boolean = false;
	@Output() backClick = new EventEmitter<void>();
	constructor(
		private router: Router,
		public repository: RepositoryService,
		private location: Location
	) {}
	home() {
		this.backClick.emit();
		if (this.isLoyaltyPages && this?.repository?.order) {
			MenuPage.navigate(this.router);
			return;
		}
		if (this.location) {
			this.location.back();
			return;
		}
		HomePage.navigate(this.router, this.repository);
	}
}
