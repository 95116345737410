import {environment} from './../../../environments/environment';
import {PreorderType} from './../../../smoothr-web-app-core/enums/PreorderType';
import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import Order from 'src/smoothr-web-app-core/models/Order';
import {AuthLoginResponse} from 'src/smoothr-web-app-core/models/AuthLoginResponse';

@Component({
	selector: 'app-image-toolbar',
	templateUrl: './image-toolbar.component.html',
	styleUrls: ['image-toolbar.component.scss']
})
export class ImageToolbarComponent implements OnInit {
	@Output()
	goToMenu = new EventEmitter<void>();
	@Output()
	close = new EventEmitter<void>();
	@Input()
	venue: Venue;
	@Input()
	order: Order;
	@Input()
	preorderType: PreorderType;
	@Input()
	authLogin: AuthLoginResponse = null;
	environment = environment;
	pr = PreorderType;
	constructor() {}

	ngOnInit() {
		console.log(this.order);
	}

	navigate() {
		this.goToMenu.emit();
	}
}
