import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {PreorderType} from 'src/smoothr-web-app-core/enums/PreorderType';
import Article from 'src/smoothr-web-app-core/models/Article';
import Compound from 'src/smoothr-web-app-core/models/Compound';
import Localization from 'src/smoothr-web-app-core/models/Localization';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import {sleep} from 'src/smoothr-web-app-core/utils/utils';
import {OverlayEventDetail} from '@ionic/core';

@Component({
	selector: 'app-map-info-modal',
	templateUrl: './map-info-modal.component.html',
	styleUrls: ['map-info-modal.component.scss']
})
export class MapInfoModalComponent implements OnInit, OnDestroy {
	preorderType: PreorderType;
	venue: Venue;
	timer;

	constructor(private modalCtrl: ModalController) {}

	ngOnInit(): void {
		this.timer = setTimeout(() => {
			this.continue();
		}, 10000);
	}

	ngOnDestroy(): void {
		clearTimeout(this.timer);
	}

	static async show(
		modalCtrl: ModalController,
		preorderType: PreorderType,
		venue: Venue
	) {
		const modal = await modalCtrl.create({
			cssClass: 'map-info-modal auto-height',
			component: MapInfoModalComponent,
			componentProps: {preorderType, venue},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
		const response = await modal.onDidDismiss();
		await sleep(100);
		return response.data as string;
	}

	close() {
		this.modalCtrl.dismiss();
	}

	continue() {
		this.modalCtrl.dismiss('continue');
	}

	address() {
		return `${this.venue.street} ${this.venue.number}, ${this.venue.postalCode} ${this.venue.city.de}`;
	}
}
