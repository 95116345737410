import {SignInPage} from './../sign-in/sign-in.page';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import validator from 'validator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AccountPage} from '../account/account.page';
import {EmailConfirmationPage} from '../email-confirmation/email-confirmation.page';
import {Api} from '../../../smoothr-web-app-core/api/api';
import {environment} from '../../../environments/environment';
import {Gender} from 'src/smoothr-web-app-core/enums/Gender';
import {HomePage} from '../home/home.page';
import {MenuPage} from '../menu/menu.page';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {ValidationUtils} from '../../../smoothr-web-app-core/utils/validation-utils';
import {AuthStrategy} from '../../../smoothr-web-app-core/models/AuthStrategy';
import {
	axiosErrorToMessage,
	venueAcceptsOrders
} from '../../../smoothr-web-app-core/utils/utils';
import {PreorderType} from 'src/smoothr-web-app-core/enums/PreorderType';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import {FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {MapsUtils} from 'src/smoothr-web-app-core/utils/maps-utils';
import {IonInput} from '@ionic/angular';
import Address from 'src/smoothr-web-app-core/models/Address';
import {debounceTime, takeUntil} from 'rxjs/operators';
import moment from 'moment';
import Customer from 'src/smoothr-web-app-core/models/Customer';

@Component({
	selector: 'app-sign-up',
	templateUrl: './sign-up.page.html',
	styleUrls: ['sign-up.page.scss']
})
export class SignUpPage implements OnDestroy, OnInit {
	static url = 'sign-up';
	static account = 'sign-up/account';
	name = 'name';
	gender = 'gender';
	vorname = 'vorname';
	email = 'email';
	agbChecked = 'agbChecked';
	emailAgree = 'emailAgree';
	password = 'password';
	rePassword = 'rePassword';
	birthday = 'birthday';
	favRestaurant = 'favourite_restaurant';
	isVegan = 'is_vegan';
	address = 'address';
	showPassword = false;
	showRePassword = false;
	public loading$ = new BehaviorSubject(false);
	public signUpForm: FormGroup;
	@ViewChild('address', {static: true}) inputField: IonInput;
	searchTerm: string = '';
	Gender = Gender;
	predictions: google.maps.places.AutocompletePrediction[] = [];
	// gender: Gender = null;
	allVenues: Venue[] = [];
	nowDate = moment().format('DD.MM.YYYY');
	accountPage = false;
	isVeganArray = [false, true];

	private onDestroy$ = new Subject();

	constructor(
		private translate: TranslateService,
		private router: Router,
		private route: ActivatedRoute,
		private repository: RepositoryService,
		private snackbarCtrl: MatSnackBar,
		private fb: FormBuilder
	) {
		// if (repository.customer && !repository.customer.isAnonymous) {
		// 	AccountPage.navigate(router);
		// }
	}
	static navigateAccount(router: Router, account = false) {
		router.navigate([SignUpPage.account], {
			queryParams: account
				? {
						account: true
				  }
				: {},
			queryParamsHandling: 'merge'
		});
	}
	static navigate(router: Router, account = false) {
		router.navigate([SignUpPage.url], {
			queryParams: account
				? {
						account: true
				  }
				: {},
			queryParamsHandling: 'merge'
		});
	}
	async ngOnInit() {
		this.loadListOfCustomerGroupVenues();
		this.createFormSignUp();
		if (this.router.url.includes('/account')) {
			this.accountPage = true;
			this.patchFormFromCustomer();
		}
		// this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe(query => {
		// 	if (query['account'] && !this.repository.customer.isAnonymous) {
		// 		this.accountPage = true;
		// 		this.patchFormFromCustomer();
		// 	}
		// });
		setTimeout(async () => {
			await this.initAutocomplete();
		}, 1000);
		this.signUpForm.valueChanges.subscribe(v => {
			console.log(v);
		});
	}
	ngOnDestroy() {
		this.onDestroy$.next();
	}

	async patchFormFromCustomer() {
		this.signUpForm.patchValue({
			[this.name]: this.repository.customer.name.split(' ')[0],
			[this.vorname]: this.repository.customer.name.split(' ')[1],
			[this.email]: this.repository.customer.email,
			[this.birthday]: moment(this.repository.customer.birthDate).format(
				'YYYY-MM-DD'
			)
		});
		if (
			this.repository.customer.street &&
			this.repository.customer.number &&
			this.repository.customer.postalCode &&
			this.repository.customer.city
		) {
			const address = await this.convertStringToAddress(
				this.repository.customer.street +
					' ' +
					this.repository.customer.number +
					',' +
					' ' +
					this.repository.customer.postalCode +
					' ' +
					this.repository.customer.city
			);
			if (address) {
				this.fillInPlace(address);
			}
		}

		console.log(this.signUpForm.value);
	}

	createFormSignUp() {
		const formControlNames: any = {};

		console.log(this.repository.customer);

		formControlNames[this.gender] = [''];
		formControlNames[this.name] = ['', [Validators.required]];
		formControlNames[this.vorname] = ['', [Validators.required]];
		formControlNames[this.email] = [
			'',
			[
				Validators.required,
				Validators.pattern(
					"^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
				)
			]
		];
		formControlNames[this.password] = !this.accountPage
			? [
					'',
					[
						Validators.required,
						Validators.minLength(8),
						Validators.pattern(/\d+/),
						Validators.pattern(/[A-Z]+/)
					]
			  ]
			: [];

		formControlNames[this.rePassword] = !this.accountPage
			? ['', [Validators.required, this._isPasswordMatch()]]
			: [];
		formControlNames[this.isVegan] = [null, [Validators.required]];
		formControlNames[this.favRestaurant] = [null, [Validators.required]];

		formControlNames[this.agbChecked] = !this.accountPage
			? [false, [Validators.required, Validators.requiredTrue]]
			: null;
		formControlNames[this.emailAgree] = !this.accountPage ? [false] : null;
		formControlNames[this.birthday] = [this.nowDate, Validators.required];
		formControlNames[this.address] = [''];
		this.signUpForm = this.fb.group(formControlNames);
		this.signUpForm.valueChanges.pipe(debounceTime(1000)).subscribe(v => {
			console.log(v);
		});
	}
	private _isPasswordMatch(): ValidatorFn {
		return () => {
			const pass = this.signUpForm?.get('password')?.value;
			const confirmPass = this.signUpForm?.get('rePassword')?.value;
			return pass === confirmPass ? null : {notSame: true};
		};
	}

	private async loadListOfCustomerGroupVenues(): Promise<void> {
		try {
			this.allVenues = (await this.repository.getAllVenues()).filter(
				ven =>
					venueAcceptsOrders(ven, PreorderType.INSIDE) ||
					venueAcceptsOrders(ven, PreorderType.TAKE_AWAY) ||
					venueAcceptsOrders(ven, PreorderType.DELIVERY)
			);
			console.log(this.accountPage, this.allVenues);
			if (this.allVenues.length > 0 && this.accountPage) {
				let preferredVenue = null;

				preferredVenue = this.allVenues.find(
					it => it._id === this.repository.customer.preferredVenue
				);
				console.log(preferredVenue);
				this.signUpForm.patchValue({
					[this.favRestaurant]: preferredVenue?._id ?? null
				});
			}
		} catch (e) {
			this.allVenues = [];
		}
	}
	isValid(controlName?: string): boolean {
		if (controlName) {
			return (
				this.signUpForm.get(controlName)?.hasError('required') &&
				this.signUpForm.get(controlName)?.touched
			);
		}

		return this.signUpForm.invalid;
	}

	isValidByPattern(controlName?: string): boolean {
		if (controlName) {
			return (
				(this.signUpForm.get(controlName)?.errors?.pattern ||
					this.signUpForm.get(controlName)?.errors?.notSame) &&
				!this.signUpForm.get(controlName)?.hasError('required')
			);
		}
		return this.signUpForm.invalid;
	}
	getControlValue(controlName?: string) {
		if (controlName) {
			return this.signUpForm?.get(controlName)?.value;
		}
		return '';
	}

	getAuthInfo() {
		const data = {
			name:
				this.getControlValue(this.name) +
				' ' +
				this.getControlValue(this.vorname),
			email: this.getControlValue(this.email),
			preferredVenues: [],
			registerInPiggy: true,
			isMobile: false,
			gender: Gender.male,
			address: this.getControlValue(this.address),
			birthDate: this.getControlValue(this.birthday)
				? new Date(
						new Date(this.getControlValue(this.birthday)).setHours(12, 0, 0, 0)
				  )
				: null,
			preferredVenue: this.getControlValue(this.favRestaurant),
			subscribedToMailing: this.getControlValue(this.emailAgree),
			vegetarian: this.getControlValue(this.isVegan) ? true : false
		} as any;

		return data;
	}

	async signUp() {
		this.signUpForm.markAllAsTouched();

		if (this.signUpForm.invalid) {
			console.warn('FORM IS INVALID');

			this.snackbarCtrl.open(
				this.translate.instant('placeholder.plz_fill_all_fields'),
				null,
				{
					duration: 3000
				}
			);
			return;
		}

		if (this.repository.customer.isAnonymous) {
			this.loading$.next(true);
			try {
				const data = this.getAuthInfo() as Customer & {address: string};
				if (data.address) {
					const addressValue = await this.convertStringToAddress(data.address);
					data.number = addressValue.number;
					data.country = addressValue.country;
					data.postalCode = addressValue.postalCode;
					data.street = addressValue.street;
					data.city = addressValue.city;
				}
				console.log(data);
				await Api.signUpWithCredentials(
					data as any,
					this.getControlValue('password')
				);

				this.loading$.next(false);
				await EmailConfirmationPage.navigate(this.router, true);
			} catch (e) {
				if (e.response.data.name === 'UserAlreadyExistsError') {
					this.signUpForm.controls['email'].setErrors({
						UserAlreadyExistsError: true
					});
				}
				this.loading$.next(false);
			}
		}
	}
	async convertStringToAddress(addressValue: string) {
		try {
			const value = await MapsUtils.getPlace(addressValue);
			if (value) {
				return MapsUtils.placeToAddress(value);
			}
			return null;
		} catch (e) {
			return null;
		}
	}
	async updateCustomer() {
		this.loading$.next(true);
		const userData = this.getAuthInfo();
		try {
			const updatedUser = {
				...this.repository.customer,
				...userData,
				customerGroup: environment.customerGroup
			} as Customer & {address: string};

			const response = await Api.patchCustomer(updatedUser);
			this.snackbarCtrl.open(this.translate.instant('account.success'), null, {
				duration: 2000
			});
			this.repository._customerAuth.customer = response.data;
			this.repository.customerAuth.next(this.repository._customerAuth);
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant('account.error'), null, {
				duration: 2000
			});
		}
		// this.loading$.next(false)
	}

	signIn() {
		SignInPage.navigate(this.router);
	}

	setEmailValid() {
		// this.isValidEmail = true;
	}

	setNameValid() {
		// this.isValidName = true;
	}

	setPasswordValid() {
		// this.isValidPassword = true;
		// this.isValidRePassword = true;
	}

	async signUpWithGoogle() {
		// if (this.agbChecked && !this.loading) {
		// 	this.loading = true;
		// 	try {
		// 		console.log('Starting signUp with Google');
		// 		const result = await Api.signIn(AuthStrategy.GOOGLE);
		// 		console.log(result);
		// 	} catch (e) {
		// 		console.error(e);
		// 	}
		// 	this.loading = false;
		// }
	}

	async signUpWithFacebook() {
		// if (this.agbChecked && !this.loading) {
		// 	this.loading = true;
		// 	try {
		// 		console.log('Starting signUp with Facebook');
		// 		const result = await Api.signIn(AuthStrategy.FACEBOOK);
		// 		console.log(result);
		// 	} catch (e) {
		// 		console.error(e);
		// 	}
		// 	this.loading = false;
		// }
	}

	async signUpWithApple() {
		// if (this.agbChecked && !this.loading) {
		// 	this.loading = true;
		// 	try {
		// 		console.log('Starting signUp with Apple');
		// 		const result = await Api.signIn(AuthStrategy.APPLE);
		// 		console.log(result);
		// 	} catch (e) {
		// 		console.error(e);
		// 	}
		// 	this.loading = false;
		// }
	}

	openTerms() {
		window.open('/tos', '_blank');
	}

	openPrivacy(event: Event) {
		event.stopPropagation();
		window.open('/privacy', '_blank');
	}

	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
	async initAutocomplete() {
		console.log(this.inputField);

		await MapsUtils.initAutocomplete(this.inputField, predictions => {
			console.log(predictions);
			this.predictions = predictions;

			if (!this.searchTerm.length) {
				this.predictions = [];
				return;
			}
			// this.predictions = predictions;
			// this.showEmpty = predictions.length === 0;
			// this.cdr.detectChanges();
		});
		// this.loading = false;
	}
	changeValue(value: any) {
		this.searchTerm = value?.detail?.value;
	}
	async loadPlace(v: any) {
		try {
			const result = await MapsUtils.executeSearch(this.inputField);
			await this.fillInPlace(result);
		} catch (e) {
			await this.fillInPlace(v);
		}
	}
	async fillInPlace(address: Address) {
		try {
			await MapsUtils.fillInPlace(this.inputField, address, term => {
				if (term) {
					this.searchTerm = term;
				}
				return this.searchTerm;
			});
			if (!address) {
				return;
			}
			this.signUpForm.patchValue({
				address:
					address.street +
					' ' +
					address.number +
					',' +
					' ' +
					address.postalCode +
					' ' +
					address.city
			});
			console.log('address', address);
			this.predictions = [];
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant(e), null, {
				duration: 2000
			});
		}
	}
}
