import {RepositoryService} from 'src/smoothr-web-app-core/services/repository/repository.service';
import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {AppComponent} from 'src/app/app.component';
import Table from 'src/smoothr-web-app-core/models/Table';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import {Api} from '../../../smoothr-web-app-core/api/api';

@Component({
	selector: 'app-pick-table',
	templateUrl: './pick-table.component.html',
	styleUrls: ['pick-table.component.scss']
})
export class PickTableComponent implements OnInit {
	venue: Venue;
	selectedTable: any = null;
	constructor(
		private modalCtrl: ModalController,
		private repository: RepositoryService
	) {}
	ngOnInit() {}
	static async show(modalCtrl: ModalController, venue: Venue) {
		const modal = await modalCtrl.create({
			component: PickTableComponent,
			componentProps: {
				venue
			},
			cssClass: AppComponent.largeScreen
				? 'item-modal large-modal'
				: 'item-modal',
			showBackdrop: true
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		return result;
	}
	async goBack() {
		await this.modalCtrl.dismiss();
	}
	selectItem(item: any) {
		this.selectedTable = item;
	}
	close() {
		this.modalCtrl.dismiss(this.selectedTable);
	}
}
