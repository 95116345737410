import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
	selector: 'app-confirmation-modal',
	templateUrl: './confirmation-modal.component.html',
	styleUrls: ['./confirmation-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationModalComponent {
	title: string;
	agreeText: string;
	disagreeText: string;
	totallyDisagreeText: string;

	constructor(private modalCtrl: ModalController) {}

	static async show(
		modalCtrl: ModalController,
		title: string = '',
		agreeText: string,
		disagreeText: string,
		totallyDisagreeText?: string
	): Promise<boolean | 'nein'> {
		const modal = await modalCtrl.create({
			component: ConfirmationModalComponent,
			cssClass: 'confirmation-modal auto-height',
			componentProps: {
				title,
				agreeText,
				disagreeText,
				totallyDisagreeText
			},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
		const response = await modal.onDidDismiss();
		return response.data;
	}

	agree() {
		this.modalCtrl.dismiss(true);
	}
	disagree() {
		this.modalCtrl.dismiss(false);
	}
	totallyDisagree() {
		this.modalCtrl.dismiss('nein');
	}
}
