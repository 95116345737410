import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';

@Component({
	selector: 'app-prepaid-balance-card',
	templateUrl: './prepaid-balance-card.component.html',
	styleUrls: ['./prepaid-balance-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrepaidBalanceCardComponent {
	@Input() title = '';
	@Input() subtitle = '';
	@Input() balance = '';
	@Input() showInput = false;
	@Input() leftToPay: number;

	@Output() onChange = new EventEmitter<number>();

	balanceValue: number;


  getValueFromBalance(): number {
    return +(this.balance.replace(/[^\d,.-]/g, '').replace(',', '.'))
  }

	onChangeInput($event) {
    let value = $event.target.value;
    let maxValue = Math.min(this.getValueFromBalance(), this.leftToPay)
		console.log(value );
		
		if (!value) {
			this.onChange.emit(0)
			return
		}

    if (+value > maxValue ) {
      value = maxValue;
    }    
    if (+value <= 0 || isNaN(value)) {
      value = 0;
    }
  
    this.balanceValue = +value;
    this.onChange.emit(+value);
	}
}
