import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Api, PAYMENT_SERVICE} from '../../api/api';
import {IonContent, ModalController} from '@ionic/angular';
import Payment from '../../models/Payment';
import {environment} from '../../../environments/environment';
import {PaymentMethod} from '../../enums/PaymentMethod';
import Venue from '../../models/Venue';
import Order from '../../models/Order';
import {PaymentStatus} from '../../models/PaymentStatus';
import Customer from '../../models/Customer';
import {OrderUtils} from '../../utils/order-utils';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
	numberToCurrency,
	paymentFromOrders,
	sleep
} from 'src/smoothr-web-app-core/utils/utils';
import {AnalyticsService} from '../../services/analytics/analytics.service';
import {TranslateService} from '@ngx-translate/core';
import {
	InAppBrowser,
	InAppBrowserOptions
} from '@ionic-native/in-app-browser/ngx';
import {AppComponent} from 'src/app/app.component';
// import {Keyboard} from '@ionic-native/keyboard/ngx';

export enum PaymentModalResult {
	SUCCESS,
	ERROR,
	DISMISS
}

// tslint:disable-next-line:prefer-const
declare var wpwlOptions: any;
// @ts-ignore
const applePayAvailable = !!window.ApplePaySession;

@Component({
	selector: 'app-payment-modal',
	templateUrl: './payment-modal.component.html',
	styleUrls: ['./payment-modal.component.scss']
})
export class PaymentModalComponent implements OnInit {
	payment: Payment;
	venue: Venue;
	orders: Order[];
	customer: Customer;
	loading = false;
	paymentMethods: PaymentMethod[] = [];
	selectedPaymentMethod: PaymentMethod = null;
	pm = PaymentMethod;
	vrUrl = environment.baseUrl + PAYMENT_SERVICE + 'vr/pay/';
	walleeUrl = '';
	orderUtils = OrderUtils;
	numberToCurrency = numberToCurrency;
	environment = environment;
	payments = {};
	wpwlOptionsBase = {
		locale: 'de',
		style: 'plain'
	};
	forceAddressInput: false;
	loadingButtonForWallee = false;
	normalOrder: Order;
	lastPayment: Payment;
	prepaidAmount = 0;
	canPayOnlyWithPiggy = false;

	@ViewChild(IonContent, {static: false}) content: IonContent;
	options: InAppBrowserOptions = {
		location: 'yes', //Or 'no'
		hidden: 'no', //Or  'yes'
		clearcache: 'yes',
		clearsessioncache: 'yes',
		zoom: 'yes', //Android only ,shows browser zoom controls
		hardwareback: 'yes',
		mediaPlaybackRequiresUserAction: 'no',
		shouldPauseOnSuspend: 'no', //Android only
		closebuttoncaption: 'Close', //iOS only
		disallowoverscroll: 'no', //iOS only
		toolbar: 'yes', //iOS only
		enableViewportScale: 'no', //iOS only
		allowInlineMediaPlayback: 'no', //iOS only
		presentationstyle: 'pagesheet', //iOS only
		fullscreen: 'yes' //Windows only
	};
	constructor(
		private modalCtrl: ModalController,
		private analytics: AnalyticsService,
		private snackBarCtrl: MatSnackBar,
		private translate: TranslateService,
		private cdr: ChangeDetectorRef,
		private theInAppBrowser: InAppBrowser // public keyboard: Keyboard
	) {}

	get order(): Order {
		return this.orders[0];
	}

	static async show(
		modalCtrl: ModalController,
		venue: Venue,
		orders: Order[],
		customer: Customer,
		tip: number = 0,
		forceAddressInput: boolean = false,
		normalOrder: Order
	): Promise<{result: PaymentModalResult; error: any; payment: Payment}> {
		const paymentMethods: PaymentMethod[] = [];
		if (venue.ccEnabled && AppComponent.isWebApp) {
			paymentMethods.push(PaymentMethod.CREDIT_CARD);
		}
		if (venue.gpEnabled) {
			paymentMethods.push(PaymentMethod.GPAY);
		}
		if (venue.apEnabled && applePayAvailable) {
			paymentMethods.push(PaymentMethod.APAY);
		}
		if (venue.ppEnabled) {
			paymentMethods.push(PaymentMethod.PAYPAL);
		}
		if (venue.sbEnabled) {
			paymentMethods.push(PaymentMethod.SOFORTBANKING);
		}
		if (venue.cashEnabled) {
			paymentMethods.push(PaymentMethod.CASH);
		}
		if (venue.pfEnabled) {
			paymentMethods.push(PaymentMethod.POSTFINANCE);
		}
		if (venue.pfcEnabled) {
			paymentMethods.push(PaymentMethod.POSTFINANCE_CARD);
		}
		if (venue.rekaEnabled) {
			paymentMethods.push(PaymentMethod.REKA);
		}
		if (venue.twintEnabled) {
			paymentMethods.push(PaymentMethod.TWINT);
		}
		if (venue.bcEnabled) {
			paymentMethods.push(PaymentMethod.BONCARD);
		}
		if (venue.lchEnabled) {
			paymentMethods.push(PaymentMethod.LUNCHECK);
		}
		if (venue.cwlEnabled) {
			paymentMethods.push(PaymentMethod.CARD_WORLDLINE);
		}
		// paymentMethods.push(PaymentMethod.CARD_WORLDLINE);

		const modal = await modalCtrl.create({
			component: PaymentModalComponent,
			componentProps: {
				payment: paymentFromOrders(orders, tip),
				paymentMethods,
				venue,
				orders,
				customer,
				forceAddressInput,
				normalOrder
			},
			backdropDismiss: false
		});
		await modal.present();
		const result = await modal.onDidDismiss();
		await sleep(100);
		return result.data;
	}

	ngOnInit() {
		this.analytics.paymentModalOpened();
	}

	async loadPayment() {
		console.log('LOAD PAYMENT', this.loading);
		if (this.loading) {
			return;
		}
		this.loading = true;
		this.resetJs();
		this.payment._id = null;
		this.payment.method = this.selectedPaymentMethod;
		// reset payment container
		this.selectedPaymentMethod = null;
		wpwlOptions = null;
		this.cdr.detectChanges();
		this.selectedPaymentMethod = this.payment.method;
		try {
			switch (this.selectedPaymentMethod) {
				case PaymentMethod.PAYPAL:
					await this.loadPaypal();
					break;

				case PaymentMethod.CREDIT_CARD:
					await this.loadCreditCard();
					break;
				case PaymentMethod.SOFORTBANKING:
					await this.loadKlarna();
					break;
				case PaymentMethod.GPAY:
					await this.loadGpay();
					break;
				case PaymentMethod.APAY:
					await this.loadApay();
					break;
				case PaymentMethod.REKA:
					await this.loadButtonForPayment(PaymentMethod.REKA);
					break;
				case PaymentMethod.TWINT:
					await this.loadButtonForPayment(PaymentMethod.TWINT);
					break;
				case PaymentMethod.POSTFINANCE:
					await this.loadButtonForPayment(PaymentMethod.POSTFINANCE);
					break;
				case PaymentMethod.POSTFINANCE_CARD:
					await this.loadButtonForPayment(PaymentMethod.POSTFINANCE_CARD);
					break;
				case PaymentMethod.BONCARD:
					await this.loadButtonForPayment(PaymentMethod.BONCARD);
					break;
				case PaymentMethod.LUNCHECK:
					await this.loadButtonForPayment(PaymentMethod.LUNCHECK);
					break;
				case PaymentMethod.CARD_WORLDLINE:
					await this.loadButtonForPayment(PaymentMethod.CARD_WORLDLINE);
					break;
			}
		} catch (e) {
			console.error(e);
			this.snackBarCtrl.open(this.translate.instant('payment_modal.error'));
			this.orders.forEach(order => {
				this.analytics.paymentLoadingFailed(order, this.selectedPaymentMethod);
			});
			this.selectedPaymentMethod = null;
			this.loading = false;
			return false;
		}
		this.loading = false;
		this.orders.forEach(order => {
			this.analytics.paymentLoaded(order, this.selectedPaymentMethod);
		});
	}

	loadVr(
		paymentMethod: PaymentMethod,
		paramsBuilder: (payment: Payment) => any
	): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const prevPayment = this.payments[paymentMethod];
				if (prevPayment) {
					console.log({paymentMethod, wpwl: prevPayment.wpwlOptions});
					this.payment = prevPayment;
					wpwlOptions = JSON.parse(JSON.stringify(this.payment.wpwlOptions));
					console.log(wpwlOptions);
					await this.loadVrJs(this.payment.vrPayment.checkoutId);
					resolve();
					return;
				}

				console.log('HERE CREATE PAYMENT', this.payment);
				let lastPayment: Payment | null = null;
				if (
					this.normalOrder &&
					(this.normalOrder?.giftCard || this.prepaidAmount)
				) {
					const multiplePayment = [];
					if (this.normalOrder && this.normalOrder?.giftCard) {
						const cardPayment = paymentFromOrders(this.orders);
						cardPayment.method = PaymentMethod.PIGGY_GIFT_CARD;
						cardPayment.piggyGiftCards = [this.normalOrder.giftCard.hash];
						multiplePayment.push(cardPayment);
					}

					if (this.normalOrder && this.prepaidAmount) {
						const prepaidPayment = paymentFromOrders(this.orders);
						prepaidPayment.method = PaymentMethod.PREPAID_BALANCE;
						console.log(this.prepaidAmount);

						prepaidPayment.piggyPrepaidBalanceUsage =
							this.prepaidAmount.toString();
						multiplePayment.push(prepaidPayment);
					}
					multiplePayment.push(this.payment);
					const createdPayment = await Api.createMultyplePayments(
						multiplePayment
					);

					lastPayment = createdPayment[createdPayment.length - 1];
				} else {
					this.payment = await Api.createPayment(this.payment);
					this.payments[paymentMethod] = JSON.parse(
						JSON.stringify(this.payment)
					);
				}

				const finalPayment = lastPayment ? lastPayment : this.payment;

				console.log('finalPayment', finalPayment);

				wpwlOptions = {
					...this.wpwlOptionsBase,
					...paramsBuilder(finalPayment)
				};
				console.log({paymentMethod, wpwl: wpwlOptions});
				finalPayment.wpwlOptions = JSON.parse(JSON.stringify(wpwlOptions));
				this.payments[paymentMethod] = JSON.parse(JSON.stringify(finalPayment));
				await this.loadVrJs(finalPayment.vrPayment.checkoutId);
				resolve();
			} catch (e) {
				reject(e);
			}
		});
	}

	async loadGpay() {
		await this.loadVr(PaymentMethod.GPAY, payment => ({
			googlePay: {
				gatewayMerchantId: payment.merchantId,
				allowedAuthMethods: ['PAN_ONLY'],
				allowedCardNetworks: ['MASTERCARD', 'VISA'],
				merchantName: this.venue.name,
				merchantId: environment.GPAY.MERCHANT_ID,
				gateway: environment.GPAY.GATEWAY,
				assuranceDetailsRequired: true
			}
		}));
	}

	async loadApay() {
		await this.loadVr(PaymentMethod.APAY, payment => ({
			applePay: {
				version: 3,
				displayName: this.venue.name,
				total: this.payment.sum,
				currencyCode: this.payment.currency,
				checkAvailability: 'canMakePayments',
				merchantIdentifier: environment.APAY_MERCHANT_ID,
				style: 'black',
				merchantCapabilities: ['supports3DS'],
				supportedNetworks: ['masterCard', 'visa'],
				shippingType: 'storePickup',
				onCancel: () => {
					console.log('onCancel');
					this.loading = false;
				},
				onPaymentAuthorized: applePayment => {
					console.log(
						'onPaymentAuthorized payment: ' + JSON.stringify(applePayment)
					);
					this.payment.status = PaymentStatus.done;
					this.orders.forEach(order => {
						this.analytics.purchase(order, this.payment);
					});
					this.modalCtrl.dismiss({
						result: PaymentModalResult.SUCCESS,
						payment: this.payment
					});
				}
			}
		}));
	}

	getBillingAddress(
		order: Order,
		customer: Customer
	): {country: string; city: string; postcode: string; street1: string} {
		if (customer && customer.street) {
			return {
				country: customer.country.toUpperCase(),
				city: customer.city,
				postcode: customer.postalCode,
				street1: customer.street + ' ' + customer.number
			};
		}
		if (this.forceAddressInput) {
			return {
				country: '',
				city: '',
				postcode: '',
				street1: ''
			};
		}
		return {
			country: order.preorder?.country?.toUpperCase(),
			city: order.preorder?.city,
			postcode: order.preorder?.postalCode,
			street1: order.preorder?.street + ' ' + order.preorder?.number
		};
	}

	resetJs() {
		const head = document.getElementsByTagName('head')[0];
		const prevVrJs = document.getElementById('vr-js');
		if (prevVrJs) {
			head.removeChild(prevVrJs);
		}
		const prevPpJs = document.getElementById('paypal-js');
		if (prevPpJs) {
			head.removeChild(prevPpJs);
		}
		//TODO Kill unneccsary code
	}

	loadVrJs(checkoutId: string): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			console.log('load: ' + environment.VR_URL + '?checkoutId=' + checkoutId);
			let widget = null;
			while (!widget) {
				this.cdr.detectChanges();
				await sleep(10);
				widget = document.getElementsByClassName('paymentWidgets')[0];
			}
			const head = document.getElementsByTagName('head')[0];
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.id = 'vr-js';
			script.src = `${environment.VR_URL}?checkoutId=${checkoutId}`;
			console.log(script);

			head.appendChild(script);
			script.onerror = () => {
				reject('Could not load script');
			};
			script.onload = async () => {
				resolve();
			};
		});
	}

	loadPaypal(): Promise<any> {
		return this.loadPaypalBase(PaymentMethod.PAYPAL, 'paypal-button');
	}

	loadKlarna(): Promise<any> {
		return this.loadPaypalBase(PaymentMethod.SOFORTBANKING, 'klarna-button');
	}

	loadPaypalCreditCard(): Promise<any> {
		return this.loadPaypalBase(PaymentMethod.CREDIT_CARD, 'paypal-cc-button');
	}

	loadPaypalJs(payment: Payment): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			const head = document.getElementsByTagName('head')[0];
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.id = 'paypal-js';
			script.src =
				environment.PAYPAL.JS_URL +
				payment.currency +
				'&merchant-id=' +
				payment.merchantId;
			script.setAttribute(
				'data-partner-attribution-id',
				environment.PAYPAL.BN_CODE
			);
			head.appendChild(script);
			script.onerror = () => reject('Could not load script');
			script.onload = () => resolve();
		});
	}
	loadPaypalButton(
		payment: Payment,
		fundingSource: any,
		elementId: string
	): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			// @ts-ignore
			const button = paypal.Buttons({
				intent: 'capture',
				commit: true,
				vault: false,
				enableStandardCardFields: true,
				createOrder: (data, actions) => {
					return payment.paypal.orderId;
				},
				onApprove: data => {
					this.finishPaypal(payment, data)
						.then(() => {
							// this.keyboard.hide();
							resolve();
						})
						.catch(err => {
							reject(err);
						});
				},
				onError: err => {
					this.loading = true;
					this.modalCtrl.dismiss({
						result: PaymentModalResult.ERROR,
						error: err
					});
				},
				fundingSource
			});
			if (!button.isEligible()) {
				reject('Button not eligible');
				return;
			}
			button.render('#' + elementId);
			resolve();
		});
	}

	async finishPaypal(payment: Payment, data: any) {
		this.loading = true;
		try {
			const result = await Api.finishPaypalPayment(data.orderID);
			this.orders.forEach(order => {
				this.analytics.purchase(order, result);
			});
			await this.modalCtrl.dismiss({
				result: PaymentModalResult.SUCCESS,
				payment: result
			});
		} catch (e) {
			this.orders.forEach(order => {
				this.analytics.paymentFailed(
					order,
					payment,
					'error finishing paypal ' + e.toString()
				);
			});
			this.loading = false;
			throw e;
		}
	}

	loadPaypalBase(
		paymentMethod: PaymentMethod,
		elementId: string
	): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const prevPayment = this.payments[paymentMethod];
				if (prevPayment) {
					this.payment = prevPayment;
					await this.loadPaypalJs(this.payment);
					// @ts-ignore
					await this.loadPaypalButton(
						this.payment,
						this.paymentMethodToPaypalFunding(paymentMethod),
						elementId
					);
					resolve();
					return;
				}
				let lastPayment: Payment | null = null;

				if (this.normalOrder && this.normalOrder?.giftCard) {
					const payment = paymentFromOrders(this.orders);
					payment.method = PaymentMethod.PIGGY_GIFT_CARD;
					payment.piggyGiftCards = [this.normalOrder.giftCard.hash];
					const createdPayment = await Api.createMultyplePayments([
						this.payment,
						payment
					]);
					console.log('created', createdPayment);
					lastPayment = createdPayment[1];
				} else {
					this.payment = await Api.createPayment(this.payment);
					this.payments[paymentMethod] = JSON.parse(
						JSON.stringify(this.payment)
					);
				}

				const finalPayment = lastPayment ? lastPayment : this.payment;

				await this.loadPaypalJs(finalPayment);
				// @ts-ignore
				await this.loadPaypalButton(
					finalPayment,
					this.paymentMethodToPaypalFunding(paymentMethod),
					elementId
				);
				resolve();
			} catch (e) {
				console.error('error while paypal setup');
				reject();
			}
		});
	}

	paymentMethodToPaypalFunding(paymentMethod: PaymentMethod): any {
		switch (paymentMethod) {
			case PaymentMethod.CREDIT_CARD:
				// @ts-ignore
				return paypal.FUNDING.CARD;
			case PaymentMethod.SOFORTBANKING:
				// @ts-ignore
				return paypal.FUNDING.CARD;
			case PaymentMethod.PAYPAL:
				// @ts-ignore
				return paypal.FUNDING.PAYPAL;
			default:
				return null;
		}
	}

	dismiss() {
		if (this.loading) {
			return;
		}
		this.modalCtrl.dismiss({
			result: PaymentModalResult.DISMISS
		});
	}
	async loadCashPay() {
		console.log('Cash Payment');
		this.loading = true;
		this.payment = await Api.createPayment(this.payment);
		this.loading = false;
		this.modalCtrl.dismiss({
			result: PaymentModalResult.SUCCESS,
			payment: this.payment
		});
	}
	async navigateToPaymentPape(url: string) {
		// window.location.assign(url);
		// setTimeout(() => {
		// 	window.open(url, '_blank');
		// }, 100);
		// window.open(url, '_blank');
		// window.location.assign(url);
		// setTimeout(() => {
		// 	window.open(url, '_blank');
		// });
		setTimeout(() => {
			let a = document.createElement('a') as any;
			document.body.appendChild(a);
			a.style = 'display: none';
			a.setAttribute('target', '_blank');
			a.href = url;
			// a.download = <your_fileName>;
			sleep(100);
			a.click();
			document.body.removeChild(a);
		}, 100);
	}

	async loadButtonForPayment(paymentMethod: PaymentMethod) {
		return await this.paymentButton(paymentMethod);
	}
	async paymentButton(paymentMethod: PaymentMethod) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				this.loadingButtonForWallee = true;
				this.payment = await Api.createWalleePayment(
					this.venue._id,
					this.payment,
					AppComponent.isNativeApp
				);
				this.payments[paymentMethod] = JSON.parse(JSON.stringify(this.payment));
				this.loadingButtonForWallee = false;
				this.loading = false;
				// await this.navigateToPaymentPape(this.payment.walleePayment.url);
				this.content.scrollToBottom(1500);
				resolve();
			} catch (e) {
				this.loadingButtonForWallee = false;
				console.error(e, 'error while button setup');
				this.loading = false;
				reject();
			}
		});
	}
	goToPayment() {}

	async loadWalleeJs(checkoutId: string, paymentId: string): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			// console.log('load: ' + environment.VR_URL + '?checkoutId=' + checkoutId);
			let widget = null;
			while (!widget) {
				this.cdr.detectChanges();
				await sleep(10);
				widget = document.getElementsByClassName('iframe-checkout-handler')[0];
			}
			const head = document.getElementsByTagName('head')[0];
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.id = 'wallee-js';
			script.src = checkoutId;
			console.log(script);
			head.appendChild(script);
			script.onerror = (e: any) => {
				console.log(e);
				reject('Could not load script');
			};
			script.onload = async () => {
				const locateWindow: any = window;
				const containerId = 'payment-form';
				const paymentMethodConfigurationId = 103431;
				const handler = locateWindow.IframeCheckoutHandler(
					paymentMethodConfigurationId
				);
				locateWindow.IframeCheckoutHandler.configure(
					'replacePrimaryAction',
					true
				);

				handler.setValidationCallback((validationResult: any) => {
					console.log('setValidationCallback', validationResult);
					// $('#payment-errors').html('');

					if (validationResult.success) {
						// Create the order within the shop and eventually update the transaction.
					} else {
						// Display errors to customer
					}
				});
				//Set the optional initialize callback
				handler.setInitializeCallback((inititalCallback: any) => {
					//Execute initialize code
					console.log('Execute initialize code', inititalCallback);
				});

				//Set the optional height change callback
				handler.setHeightChangeCallback((height: any) => {
					//Execute code
					console.log('height', height);
				});

				handler.create(containerId);
				resolve();
			};
		});
	}
	async loadCreditCard() {
		if (!this.venue.vrPaymentEnabled && this.venue.ppFullIntegrationEnabled) {
			await this.loadPaypalCreditCard();
		}

		await this.loadVr(PaymentMethod.CREDIT_CARD, payment => ({
			billingAddress: this.getBillingAddress(this.order, this.customer),
			mandatoryBillingFields: {
				country: true,
				state: false,
				city: true,
				postcode: true,
				street1: true
			}
		}));
	}
	openUrl(url: string) {
		let target = '_system';
		this.dismiss();
		this.theInAppBrowser.create(url, target, this.options);

		// this.dismiss();
	}

	formatCurrency(amount: number, currency: string): string {
		return new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: currency,
			minimumFractionDigits: 0 // Removes decimal if not needed
		})
			.format(amount)
			.replace(/\s/g, ''); // Remove spaces if any
	}

	onChangePrepaidInput(prepaidForUsage: number) {
		this.prepaidAmount = prepaidForUsage;
		this.canPayOnlyWithPiggy =
			this.orderUtils.orderTotalPrice(
				this.normalOrder,
				true,
				true,
				true,
				true,
				this.prepaidAmount
			) === 0;

		this.selectedPaymentMethod = null;
		this.payments = {};
	}

	async payOnlyWithPiggy() {
		// if (!this.canPayOnlyWithPiggy) {return}

		const multiplePayment = [];
		if (this.normalOrder && this.normalOrder?.giftCard) {
			const cardPayment = paymentFromOrders(this.orders);
			cardPayment.method = PaymentMethod.PIGGY_GIFT_CARD;
			cardPayment.piggyGiftCards = [this.normalOrder.giftCard.hash];
			multiplePayment.push(cardPayment);
		}

		if (this.normalOrder && this.prepaidAmount) {
			const prepaidPayment = paymentFromOrders(this.orders);
			prepaidPayment.method = PaymentMethod.PREPAID_BALANCE;
			console.log(this.prepaidAmount);

			prepaidPayment.piggyPrepaidBalanceUsage = this.prepaidAmount.toString();
			multiplePayment.push(prepaidPayment);
		}

		console.log('multiplePayment', multiplePayment);

		const createdPayment = await Api.createMultyplePayments(multiplePayment);
		if (createdPayment) {
			this.modalCtrl.dismiss({
				result: PaymentModalResult.SUCCESS,
				error: null,
				payment: createdPayment[createdPayment.length - 1]
			});
		} else {
			return
		}
		console.log('created', createdPayment);
	}
}
