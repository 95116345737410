import {Component, Input, EventEmitter, Output} from '@angular/core';
import {Router} from '@angular/router';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from 'src/app/services/navigation.service';
import {environment} from 'src/environments/environment';
import {OrderType} from 'src/smoothr-web-app-core/enums/OrderType';
import {PreorderType} from 'src/smoothr-web-app-core/enums/PreorderType';
import {TerminalorderType} from 'src/smoothr-web-app-core/enums/TerminalorderType';
import Article from 'src/smoothr-web-app-core/models/Article';
import {Coupon} from 'src/smoothr-web-app-core/models/Coupon';
import Customer from 'src/smoothr-web-app-core/models/Customer';
import Order from 'src/smoothr-web-app-core/models/Order';
import {RepositoryService} from 'src/smoothr-web-app-core/services/repository/repository.service';
import {isAvailable, sleep} from 'src/smoothr-web-app-core/utils/utils';
import {ShowPersonalPromocodeComponent} from '../show-personal-promocode/show-personal-promocode.component';

interface AshPointsItem {
	name: string;
	subtitle: string;
	img: string;
	accept: boolean;
	description: string;
	points: number;
	_id: string;
}

@Component({
	selector: 'app-loyalty-modal-info',
	templateUrl: './loyalty-modal-info.component.html',
	styleUrls: ['loyalty-modal-info.component.scss']
})
export class LoyaltyModalInfoComponent {
	@Input() item: Article;
	@Output() close = new EventEmitter<void>();
	@Input() order: Order;
	@Input() customerData: Customer;
	@Output() addPromocode = new EventEmitter<Coupon>();

	constructor(
		private repository: RepositoryService,
		private modalCtrl: ModalController,
		private navService: NavigationService,
		private router: Router,
		private translate: TranslateService
	) {}

	static async show(modalCtrl: ModalController, item: AshPointsItem) {
		const modal = await modalCtrl.create({
			cssClass: 'loyalty-modal-info',
			component: LoyaltyModalInfoComponent,
			componentProps: {
				item
			},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();

		const result = (await modal.onDidDismiss()).data;
		return result;
	}

	closeClick() {
		this.close.emit();
	}

	async inTheSot(item: Article) {
		await ShowPersonalPromocodeComponent.show(
			this.modalCtrl,

			this.translate.instant('loyalty_page.personal_qr'),
			this.translate.instant('loyalty_page.in_restaurant_qr'),
			environment.urlQrCode +
				'user/' +
				this.customerData?.uid +
				'/article/' +
				item?._id
		);
	}
	async inTheTresen(item: Article) {
		await ShowPersonalPromocodeComponent.show(
			this.modalCtrl,
			'Personal QR Code',
			'Bitte zeigen Sie QR Code es dem Kellner',
			environment.urlQrCode +
				'user/' +
				this.customerData?.uid +
				'/article/' +
				item?._id
		);
	}
	inTheApp() {
		if (this.order) {
			this.router.navigateByUrl(`/menu?itemId=${this.item?._id}`, {
				replaceUrl: true
			});
		} else {
			this.repository.createOrder(
				this.repository._venue,
				null,
				OrderType.PREORDER,
				PreorderType.TAKE_AWAY
			);
			sleep(200);
			this.router.navigateByUrl(`/menu?itemId=${this.item?._id}`, {
				replaceUrl: true
			});
		}
	}
	checkInSotAvaibility(article: Article) {
		if (article) {
			return isAvailable(
				article?.availability,
				OrderType.TERMINAL,
				null,
				TerminalorderType.INSIDE
			);
		}
		return true;
	}
}
